<template>
  <div class="delivery-card" v-if="delivery">
    <div class="" >
      <div class="d-flex mb-1 text-muted justify-content-between">
          <span class="fw-bold">
            Spot {{delivery.spot}}
          </span>
      </div>
      <div class="delivery-first-file shadow"
        :style="`background-image: url(${absoluteUrl(getFirstFile)})`" >

      </div>
      <div class="">
        <div class="mt-1 text-center">
          <p class="small mb-0" v-if="delivery.worker" style="color:#ccc;">
            {{delivery.files.length}} file(s)  <a class="text-capitalize" href="javascript:void(0)" >by {{delivery.worker.username}} </a></p>
        </div>
        <div class="d-flex justify-content-center">
            <ul v-if="delivery.is_reviewed" class="list-inline mb-0">
              <li class="list-inline-item mr-1" v-for="star in parseInt(delivery.review.rating)" :key="'0-'+star">
                <img :src="absoluteUrl('/images/svg/star.svg')" alt="Review rating" width="12">
              </li>
              <li class="list-inline-item mr-1" v-for="nostar in  ((delivery.review.rating % 1 == 0 ? 5 : 4) - parseInt(delivery.review.rating))" :key="nostar">
                <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12">
              </li>
            </ul>
            <ul v-else class="list-inline mb-0">
              <li class="list-inline-item mr-1" v-for="nostar in  5" :key="nostar">
                <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12">
              </li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
   props: {
    delivery : { type: Object,  default: null },
    dIndex: { type: Number, required : true}
  },
  computed:{
    getFirstFile(){
      return this.delivery.files[0] ? this.delivery.files[0].path : ''
    }
  },
  methods: {

  },

}
</script>

<style lang="scss">
.delivery-card .delivery-first-file {
    height:200px;
    background-position: center center;
    background-size: cover;
}

@media(max-width:568px){
    .delivery-card .delivery-first-file {
        height:100px;
    }
}

</style>
