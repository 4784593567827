<template>
    <div class="row px-2">
        <div class="col-6 g-2" v-for="(file, fIndex) in files" :key="fIndex">
            <div class="card my-0 overflow-hidden shadow-none border text-left">
                <div class="" v-if="isImageFile(file.extension)">
                    <img @click="() => showImg(fIndex)" class="cursor-pointer img-fluid"
                    :src="absoluteUrl(file.path)" :alt="file.name">
                </div>
                <div class="p-2">
                    <div class="row align-items-center">
                        <div class="col-auto d-none d-sm-block">
                            <span class="badge mb-2 bg-primary small p-1 rounded text-uppercase">{{file.extension}}</span>
                        </div>
                        <div class="col">
                            <a :href="absoluteUrl(`/download?file_path=${file.path}`)"
                                :download="file.name" class="text-muted">
                                <div class="d-none mb-2 d-sm-block text-muted">{{file.name}}</div>
                                <div class="mb-0 d-flex align-items-center small"><i class="me-2 ri-download-2-fill"></i>{{file.size}}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "message-files",
    props: {
        files: {
            type: Array,
            required: true,
        },
    },
    methods:{
        showImg(imgIndex){
            this.$emit("showImg", {files: this.files, index: imgIndex})
        }
    }
}
</script>
