<template>

<div class="row delivery-files g-3">
    <div v-for="(file, fIndex) in files" :key=" fIndex" :class="`col-6 ${columnClass}`">
        <div>
            <div v-if="isImageFile(file.extension)" @click="() => showImg(fIndex)"  class="delivery-image-file shadow"
                :style="`background-image: url(${absoluteUrl(file.path)})`" >
            </div>
            <div v-else  class="delivery-image-file shadow d-flex align-items-center justify-content-center" >
                <h4 class="mb-0">{{file.size}} - {{file.extension}}</h4>
            </div>
        </div>
        <p class="mb-0">
            <a :href="absoluteUrl(`/download?file_path=${file.path}`)" :download="file.name"
            class="mb-2"> <span class="text-success small"> Download ({{file.size}}) - ({{file.extension}})</span></a>
        </p>
    </div>
    <vue-easy-lightbox :visible="visible" :imgs="lightboxFiles" :index="lightboxIndex" @hide="handleHide"></vue-easy-lightbox>
</div>

</template>

<script>

import VueEasyLightbox from "vue-easy-lightbox";

export default {
    name: "delivery-files",
    components:{
        VueEasyLightbox
    },
    props: {
        files: {
            type: Array,
            required: true,
        },
        columnClass:{
            type: String,
            default: "col-lg-3",
        }
    },
    data() {
        return {
            visible: false,
            lightboxIndex: null,
        }
      },
    computed: {
        imageVideoFiles(){
            return this.files.filter((file) => ['png','jpg','svg','jpg','webp'].includes(file.extension.toLowerCase()) || file.is_video)
        },
        lightboxFiles(){
            return this.files.map(file => {
                if(file.is_video){
                  return {
                      title: file.name,
                      thumb: this.absoluteUrl(file.path),
                      mediaType: 'video',
                      src: this.absoluteUrl(file.video_path) }
                }else{
                  return {
                      title: file.name,
                      mediaType: 'image',
                      src: this.absoluteUrl(file.path) }
                }
            })
        },
    },
    methods: {
        showImg(index) {
            this.lightboxIndex = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
    }
}
</script>

<style scoped>

.delivery-files .delivery-image-file {
    height:150px;
    background-position: center center;
    background-size: cover;
}

@media(max-width:568px){
    .delivery-files .delivery-image-file {
        height:100px;
    }
}

</style>
