<template>
     <div class="row gy-2">
        <div class="col-md-8">
            <h5 class="font-weight-bold">Rate Designer</h5>
            <div>
                <star-rating 
                    v-model:rating="data.rating"
                    :star-size="30"></star-rating>
            </div>
            <div class="mt-3">
                <div class="w-100">
                    <textarea  v-model="data.content" class="form-control mb-0"
                    :class="{ 'is-invalid': v$.data.content.$error }"
                    rows="5" placeholder="Share your experience" name="review"></textarea>
                    <div v-for="(item, index) in v$.data.content.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 d-flex align-items-end">
            <div class="text-center">
                <button type="button" @click.prevent="submit()" class="btn btn-primary mt-2">
                    <i class="mdi mdi-content-save"></i> Submit
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import StarRating from 'vue-star-rating'

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        StarRating
    },
    props:{
        deliveryId:{
            type: Number,
            required: true
        }
    },
    data() {
        return {
            data:{
                rating: null,
                content: ""
            },
        }
    },
    validations() {
        return {
            data:{
                rating:{ required: required},
                content:{ required: required}
            }
        }
    },
    methods:{
        submit(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError("Form Not Valid"); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/deliveries/${this.deliveryId}/review`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("orderList/SET_DELIVERY", response.data.data.delivery)
                        this.resetForm();
                    }
                })
            });
        },
        resetForm() {
            this.data = {
                rating: null,
                content: ""
            },
            this.v$.$reset()
        },
    },
}
</script>
