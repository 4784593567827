
<template>
  <div>
     <vue-dropzone id="dropzone0111" ref="fileUploadDrop" 
     :use-custom-slot="true" :maxFiles="10">
      <div class="needsclick m-0">
        <h6>Drop files here or click to upload.</h6>
        <span class="text-muted small">
          (Each File should not be more than 10MB)
        </span>
      </div>
    </vue-dropzone>
    <div class="mt-3">
      <b-button variant="primary" @click.prevent="sendAttachment()" type="button">Upload</b-button>
    </div>
</div>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";

export default {
  components:{
    VueDropzone
  },
  name: "upload-files",
  methods: {
    sendAttachment() {
      if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
      this.$emit("onUpload",this.collectFiles())
    },
    collectFiles(){
      let arrafile = [];
      if(this.$refs.fileUploadDrop){
        arrafile = [...this.$refs.fileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
  }
}

</script>

